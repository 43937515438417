import Link from 'next/link';
import styled, { css } from 'styled-components';

import { Typography, HelpIcon } from '@whiteaway/ui';

import { useTranslations } from '@/contexts';

/**
 * The AuthHelpLink component holds a link to the help page from the auth section of the app.
 */
export const AuthHelpLink: React.FC = (props) => {
  const { translations } = useTranslations();

  const texts = translations.layouts.auth.helpLink;

  return (
    <RootAuthLayoutHelpLink href="/help" {...props}>
      <HelpIcon />

      <Typography asElement="span">{texts.label}</Typography>
    </RootAuthLayoutHelpLink>
  );
};

export default AuthHelpLink;

const RootAuthLayoutHelpLink = styled(Link)`
  ${({ theme }) => css`
    display: flex;
    margin-top: ${theme.getSpacing(8)};

    gap: ${theme.getSpacing(1)};

    &:hover {
      text-decoration: underline;
    }
  `};
`;
