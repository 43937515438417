import Image from 'next/image';
import styled, { css } from 'styled-components';

import { Stack, Typography, List, ListItem } from '@whiteaway/ui';

import { useTranslations } from '@/contexts';

import { AuthHelpLink } from '../';

/**
 * The AuthHelpSection component holds the help section that is displayed on all the different auth pages.
 * The component is hidden on mobile.
 */
export const AuthHelpSection: React.FC = () => {
  const { translations } = useTranslations();

  const texts = translations.layouts.auth.helpSection;
  const logoAltText = translations.shared.logoAltText;

  return (
    <AuthHelpSectionRoot justify="space-between">
      <Image src="/logo-negative.png" alt={logoAltText} width={500} height={116} />

      <Stack spacing={8} justify="space-between">
        <Stack spacing={3}>
          <Typography variant="h3">{texts.header}</Typography>

          <AuthHelpSectionList variant="Unordered">
            {texts.items.map((item, index) => (
              <ListItem key={index}>{item}</ListItem>
            ))}
          </AuthHelpSectionList>
        </Stack>

        <AuthHelpLink />
      </Stack>
    </AuthHelpSectionRoot>
  );
};

AuthHelpSection.displayName = 'AuthHelpSection';

export default AuthHelpSection;

const AuthHelpSectionRoot = styled(Stack)`
  ${({ theme }) => css`
    flex-basis: 100%;

    padding: ${theme.getSpacing(12)};
    color: ${theme.colors.text.inverse};

    background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('/auth-background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 21% center;

    ${theme.breakpoints.below('desktop')} {
      display: none;
    }
  `}
`;

const AuthHelpSectionList = styled(List)`
  max-width: 500px;
`;
