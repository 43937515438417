'use client';

import React from 'react';
import styled, { css } from 'styled-components';

import { AuthHelpLink, AuthHelpSection } from './_components';

interface Props {
  children: React.ReactNode;
}

export const AuthLayoutClient: React.FC<Props> = (props) => {
  const { children } = props;

  return (
    <AuthLayoutClientRoot>
      <AuthHelpSection />

      <AuthLayoutClientContent>
        <AuthLayoutClientContentInner>
          {children}

          <AuthLayoutClientHelpLink />
        </AuthLayoutClientContentInner>
      </AuthLayoutClientContent>
    </AuthLayoutClientRoot>
  );
};

const AuthLayoutClientRoot = styled.section`
  display: flex;
  min-height: 100vh;
`;

const AuthLayoutClientContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    flex-basis: 100%;
    justify-content: center;

    padding: ${theme.getSpacing(4)};
    background: ${theme.colors.background.default};
  `}
`;

const AuthLayoutClientContentInner = styled.div`
  width: 100%;
  max-width: 450px;
  margin-inline: auto;
`;

const AuthLayoutClientHelpLink = styled(AuthHelpLink)`
  ${({ theme }) => css`
    ${theme.breakpoints.above('desktop')} {
      display: none;
    }
  `}
`;
